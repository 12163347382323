import React, { useState} from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./_NewDepForm.scss";
import { useDispatch } from "react-redux";
import { createDepartment } from "../../../slices/departement";
import AddDep from "./AddDepComponent";
import Notice from "../../../components/Notice";
import "react-toastify/dist/ReactToastify.css";

function NewDepForm({ handleClose, id }) {
  const dispatch = useDispatch();

  const [notice, setNotice] = useState({
    open: false,
    text: "",
    state: "",
  });
  const [myImg, setMyImg] = useState(false);

  const initialValues = {
    name: "",
    description: "",
    img: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .required(" Name is Required!"),

    description: Yup.string()
      .min(10, "Description must be at least 10 characters")
      .required("Department description is required!"),
  });

  const onSubmit = async (values, submitProps, resetForm) => {
    const data = {
      name: values.name,
      img: values.img,
      description: values.description,
    };

    await dispatch(createDepartment(data)).then((res) => {
      if (res?.error) {
        setNotice({
          text: res?.error?.message,
          open: true,
          state: "error",
        });
        setTimeout(() => {
          setNotice({
            open: false,
            state: "error",
          });
        }, 1500);
        submitProps.setStatus({ success: false });
        submitProps.setSubmitting(false);
      } else {
        if (res?.payload?.message) {
          setNotice({
            text: res?.payload?.message,
            open: true,
            state: "success",
          });
          submitProps.setStatus({ success: true });
          submitProps.setSubmitting(true);
          setTimeout(() => {
            handleClose(id);
          }, 1500);
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <>
            <Notice
              open={notice.open}
              text={notice.text}
              state={notice.state}
            />
            <Form>
              <AddDep
                formik={formik}
                setMyImg={setMyImg}
                myImg={myImg}
                className={
                  !formik.isValid || formik.isSubmitting || myImg
                    ? " blueButton disabled-button "
                    : "blueButton "
                }
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default NewDepForm;
